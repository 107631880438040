import Vue from 'vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const order = [{
  path: "/order",
  name: "order",
  component: () =>
    import ("../pages/order/lst.vue"),
  meta: {
    activeName: "order",
    openName: "orders"
  }
}, {
  path: "/order/edit/:id",
  name: "orderEdit",
  component: () =>
    import ("../pages/order/edit.vue"),
  meta: {
    activeName: "orderEdit",
    openName: "orders"
  }
}, {
  path: "/orderItems",
  name: "orderItems",
  component: () =>
    import ("../pages/items/lst.vue"),
  meta: {
    activeName: "orderItems",
    openName: "orders",
    fixedOrder: true
  }
}, {
  path: "/testingSyllabus",
  name: "testingSyllabus",
  component: () =>
    import ("../pages/testingSyllabus/lst.vue"),
  meta: {
    activeName: "testingSyllabus",
    openName: "orders",
    fixedOrder: true
  }
}, {
  path: "/bom",
  name: "bom",
  component: () =>
    import ("../pages/bom/lst.vue"),
  meta: {
    activeName: "bom",
    openName: "orders",
    fixedOrder: true
  }
}, {
  path: "/bomSend",
  name: "bomSend",
  component: () =>
    import ("../pages/bomSend/lst.vue"),
  meta: {
    activeName: "bomSend",
    openName: "orders",
    fixedOrder: true
  }
}, {
  path: "/startBill",
  name: "startBill",
  component: () =>
    import ("../pages/startBill/lst.vue"),
  meta: {
    activeName: "startBill",
    openName: "orders",
    fixedOrder: true
  }
}, {
  path: "/cash-collection-plan",
  name: "cashCollectionPlan",
  component: () =>
    import ("../pages/cashCollectionPlan/lst.vue"),
  meta: {
    activeName: "cashCollectionPlan",
    openName: "orders",
    fixedOrder: true
  }
}, {
  path: "/invoice/send",
  name: "invoiceSend",
  component: () =>
    import ("../pages/invoice/send.vue"),
  meta: {
    activeName: "invoiceSend",
    openName: "orders",
    fixedOrder: true
  }
}]

const equipment = [{
  path: "/equipment",
  name: "equipment",
  component: () =>
    import ("../pages/equipment/lst.vue"),
  meta: {
    activeName: "equipment",
    openName: "equipment"
  }
}, {
  path: "/equipment/mro",
  name: "equipmentMro",
  component: () =>
    import ("../pages/equipment/mro.vue"),
  meta: {
    activeName: "equipmentMro",
    openName: "equipment"
  }
}, {
  path: "/equipment/use-trace",
  name: "equipmentUseTrace",
  component: () =>
    import ("../pages/equipment/use-trace.vue"),
  meta: {
    activeName: "equipmentUseTrace",
    openName: "equipment"
  }
}]

const goods = [{
  path: "/goods",
  name: "goods",
  component: () =>
    import ("../pages/goods/lst.vue"),
  meta: {
    activeName: "goods",
    openName: "goods"
  }
}, {
  path: "/goodsEdit",
  name: "goodsEdit",
  component: () =>
    import ("../pages/goods/edit.vue"),
  meta: {
    activeName: "goods",
    openName: "goods"
  }
}, {
  path: "/stock",
  name: "stock",
  component: () =>
    import ("../pages/goods/stock.vue"),
  meta: {
    activeName: "stock",
    openName: "goods"
  }
}, {
  path: "/stockIn",
  name: "stockIn",
  component: () =>
    import ("../pages/goods/stockIn.vue"),
  meta: {
    activeName: "stockIn",
    openName: "goods"
  }
}, {
  path: "/stockOut",
  name: "stockOut",
  component: () =>
    import ("../pages/goods/stockOut.vue"),
  meta: {
    activeName: "stockOut",
    openName: "goods"
  }
}, {
  path: "/storage",
  name: "storage",
  component: () =>
    import ("../pages/goods/storage.vue"),
  meta: {
    activeName: "storage",
    openName: "goods"
  }
}, {
  path: "/prototypes-deal",
  name: "prototypesDeal",
  component: () =>
    import ("../pages/prototypesDeal/lst.vue"),
  meta: {
    activeName: "prototypesDeal",
    openName: "goods"
  }
}]

const crm = [{
  path: "/crm/order",
  name: "crmOrder",
  component: () =>
    import ("../pages/crm/order.vue"),
  meta: {
    activeName: "crmOrder",
    openName: "crm"
  }
},{
  path: "/crm/trader",
  name: "crmTrader",
  component: () =>
    import ("../pages/crm/trader/lst.vue"),
  meta: {
    activeName: "crmTrader",
    openName: "crm"
  }
}]

const sys = [{
  path: "/dept",
  name: "dept",
  component: () =>
    import ("../pages/sys/dept/lst.vue"),
  meta: {
    activeName: "dept",
    openName: "org"
  }
}, {
  path: "/user",
  name: "user",
  component: () =>
    import ("../pages/sys/user/lst.vue"),
  meta: {
    activeName: "user",
    openName: "org"
  }
}, {
  path: "/guest",
  name: "guest",
  component: () =>
    import ("../pages/sys/guest/lst.vue"),
  meta: {
    activeName: "guest",
    openName: "org"
  }
}, {
  path: "/role",
  name: "role",
  component: () =>
    import ("../pages/sys/role/lst.vue"),
  meta: {
    activeName: "role",
    openName: "sys"
  }
}, {
  path: "/survey",
  name: "survey",
  component: () => import("../pages/survey/lst.vue"),
  meta: {
    activeName: "survey",
    openName: "survey"
  }
}]

const routes = [{
  path: '/',
  name: "main",
  component: () =>
    import ('../pages/index.vue'),
  children: [{
    path: '/home',
    name: 'home',
    component: () =>
      import ('../pages/home/index.vue')
  }].concat(order, goods, equipment, crm, sys)
}, {
  path: '/login',
  name: 'login',
  component: () =>
    import ("../pages/login.vue")
}, {
  path: "/survey/submited",
  name: "surveySubmited",
  component: () => import("../pages/survey/submited.vue")
}, {
  path: "/survey/preview",
  name: "surveyPreview",
  component: () => import("../pages/survey/preview.vue")
}]

const router = new VueRouter({
  routes
})

export default router