let able = {
    map: {},
    set: function(codes) {
        this.map = {}
        if (codes && codes.length>0) {
            for (let code of codes) {
                let a = code.split("-")
                if (a[0]) {
                    this.map[a[0]] = true
                }
                if (a[1]) {
                    this.map[a[0]+a[1]] = true
                }
                let i = code.replace(/\-/g, '')
                this.map[i] = true
              }
        }
        return this.map
    }
}
export default able