export default {
  uuid: uuid,
  copy: v => JSON.parse(JSON.stringify(v)),
  // copy: copy,
  obj2params: obj2params,
  index2Abc: index2Abc,
  hourParser: hourParser,
  selectValue: selectValue,
  file2url: file2url,
  session: session,
  session_t: session_t,
  clearSession: clearSession,
  getByCode: getByCode,
  color,
  fileSizeFormat,
  renderSize
}
/**
 * 去抖
 * 使用
 * methods: {
    methodName: util.debounce(function () {
      console.log('TODO')
    }, 1000)
*/
export function debounce(func, delay) {
  let timer
  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
// 节流器
export function throttle(action, delay) {
  let last = 0
  return function (...args) {
    let curr = +new Date()
    if (curr - last > delay) {
      action.apply(this, args)
      last = curr
    }
  }
}
// 缓存
export function session(key, value, config) {
  if (value) {
    let d = JSON.stringify(value)
    let t = new Date().getTime()
    localStorage.setItem(key, d)
    localStorage.setItem(key + '_t', t)
  } else {
    return localStorage.getItem(key)
  }
}
// 缓存时间戳
export function session_t(key) {
  if (key) {
    let t = new Date().getTime()
    localStorage.setItem(key + '_t', t)
  }
}
// 清除缓存和时间戳
export function clearSession(key) {
  if (key) {
    localStorage.removeItem(key)
    localStorage.removeItem(key + '_t')
  }
}
// 获取缓存[值，时间戳]
function getSession(key) {
  let d = localStorage.getItem(key)
  let t = localStorage.getItem(key + '_t')
  try {
    d = JSON.parse(d)
  } catch (e) {
    d = localStorage.getItem(key)
  }
  t = t ? t * 1 : ''
  return { d: d, t: t }
}
// uuid
function uuid(len, radix) {
  /* use
    // 8 character ID (base=2)
    uuid(8, 2)  //  "01001010"
    // 8 character ID (base=10)
    uuid(8, 10) // "47473046"
    // 8 character ID (base=16)
    uuid(8, 16) // "098F4D35"
  */
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  var uuid = [], i
  radix = radix || chars.length
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
  } else {
    // rfc4122, version 4 form
    var r
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}
// 对象转url参数
function obj2params(obj) {
  let arr = []
  if (obj) {
    for (let i in obj) {
      arr.push(i + '=' + obj[i])
    }
  }
  return '?' + arr.join('&')
}

// 
function hourParser(value) {
  console.log(value)
  if (value) {
    if (typeof value == 'string') {
      let res = value.match(/(\d+(\.\d{1,2})?)/)
      if (res && res[0]) {
        return res[0]
      }
    }
  }
  return ''
}
// 
function selectValue(e) {
  console.log(e)
  e.currentTarget.select()
}

// 本地文件临时地址
function file2url(file) {
  let url = ''
  if (window.createObjectURL !== undefined) { // basic
    url = window.createObjectURL(file)
  } else if (window.URL !== undefined) { // mozilla(firefox)
    url = window.URL.createObjectURL(file)
  } else if (window.webkitURL !== undefined) { // webkit or chrome
    url = window.webkitURL.createObjectURL(file)
  }
  return url
}

// 根据index获取ABC
/**
 * 小写[97, 122]
 * 大写[65, 90]
 * @param {Int} i 索引
 * @param {String} case_ 大小写 ABC abc
 */
function index2Abc(i, case_) {
  let index = 97 + i * 1
  if (case_ == "ABC") {
    index = 65 + i * 1
  }
  return String.fromCharCode(index)
}


/**
 * 
 * 1、用new obj.constructor ()构造函数新建一个空的对象，而不是使用{}或者[],这样可以保持原形链的继承；
 * 2、用obj.hasOwnProperty(key)来判断属性是否来自原型链上，因为for..in..也会遍历其原型链上的可枚举属性。
 * 3、上面的函数用到递归算法，在函数有名字，而且名字以后也不会变的情况下，这样定义没有问题。但问题是这个函数的执行与函数名 factorial 紧紧耦合在了一起。
 * 为了消除这种紧密耦合的现象，需要使用 arguments.callee。
 */
function copy(obj) {
  if (obj === null) return null
  if (typeof obj !== 'object') return obj;
  if (obj.constructor === Date) return new Date(obj);
  let newObj = new obj.constructor();  //保持继承链
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {   //不遍历其原型链上的属性
      let val = obj[key];
      newObj[key] = typeof val === 'object' ? arguments.callee(val) : val; // 使用arguments.callee解除与函数名的耦合
    }
  }
  return newObj;
}

/**
 * 获取常量
 */
import store from '../store/index'
function getByCode(code) {
  let lst = []
  if (store.state.confConst) {
    for (let i of store.state.confConst) {
      if (i.TypeCode == code) {
        lst.push(i)
      }
    }
  }
  return lst
}

function color(str) {
  return '<span style="color:#2b85e4;">' + str + '</span>'
}

function fileSizeFormat(b) {
  if (b < 1024) {
    return b.toFixed(2).replace(".00", "") + "B";
  } else if (b < 1048576) {
    return (b / 1024).toFixed(2).replace(".00", "") + "KB";
  } else if (b < 1073741824) {
    return (b / 1048576).toFixed(2).replace(".00", "") + "MB";
  } else {
    return (b / 1073741824).toFixed(2).replace(".00", "") + "GB";
  }
}

function renderSize(value) {
  if (null == value || value == '') {
    return "0 Bytes";
  }
  let unitArr = new Array("Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
  let index = 0;
  let srcsize = parseFloat(value);
  index = Math.floor(Math.log(srcsize) / Math.log(1024));
  let size = srcsize / Math.pow(1024, index);
  size = size.toFixed(2);//保留的小数位数
  return size + unitArr[index];
}