import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    contentHeight: 0,
    user: "",
    flag401: true,
    // _____
    tabs: [
      { label: "首页", name: "home" }
    ],
    tabOpts: {},
    order: {},
    tabName: "",
    modalStyles: { top: '50px' }
  },
  mutations: {
    setContentHeight(state, value, that) {
      state.contentHeight = value
    },
    setUser(state, value) {
      state.user = value
      state.flag401 = true
    },
    setFlag401(state, flag) {
      state.flag401 = flag
    },
    setTabs(state, tabs) {
      state.tabs = tabs
    },
    setTabName(state, name) {
      state.tabName = name
    },
    setOrderLocate(state, data) {
      state.order = data || {}
    },
    setTabOpts(state, data) {
      state.tabOpts = data || {}
    }
  },
  actions: {
  },
  modules: {
  }
})
