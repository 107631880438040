/* eslint-disable */
import Vue from 'vue'
import axios from "axios"
import router from "../router"
import iView from 'view-design'
import store from '../store/index'
import { session_t, clearSession } from './util'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // baseURL: process.env.NODE_ENV == 'development' ? '/proxy/' : ''
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    if (response.data.result) {
      session_t('user')
      return response.data
    } else if (response.data.errorcode === 401) {
      console.log("flag401", store.state.flag401)
      clearSession('user')
      Vue.prototype.$able.set()
      console.log("response.config.url", response.config.url)
      if (response.config.url == "/Api/BeikeUser/UserSession") {
        router.push({ name: "login" })
        store.commit('setFlag401', false)
      } else {
        if (store.state.flag401) {
          store.commit('setFlag401', false)
          iView.Modal.confirm({
            title: '提示',
            content: response.data.errormsg,
            onOk: () => {
              router.push({ name: "login" })
            },
            onCancel: () => {
              store.commit('setFlag401', true)
            }
          })
          return Promise.reject({ errormsg: "" });
        }
      }
    } else {
      // return Promise.reject(response.data);
      return response.data
    }
  },
  function (error) {
    // Do something with response error
    console.log(error)
    let error_ = { errormsg: "网络错误" }
    return Promise.reject(error_)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    },
  })
}

Vue.use(Plugin)

export default Plugin