import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import './plugins/axios.js'

import ViewUI from 'view-design';
import './theme/theme.less'
import './theme/common.less'
import './theme/bk.less'

import './plugins/base.js'
import util from './plugins/util.js'
import able from "./plugins/able"
import tab from "./plugins/tab"
import designConst from "./plugins/designConst"
import confConst from "./plugins/confConst"

import CKEditor from 'ckeditor4-vue';

import viewer from 'vue2-viewer';

Vue.config.productionTip = false

// Vue.use(TabRouter)
// Vue.use(TabMethods)

const $util = {
  install: Vue => Vue.prototype.$util = util
}
Vue.use($util)

const $able = {
  install: Vue => Vue.prototype.$able = able
}
Vue.use($able)

const $tab = {
  install: Vue => Vue.prototype.$tab = tab
}
Vue.use($tab)

const $designConst = {
  install: Vue => Vue.prototype.$designConst = designConst
}
Vue.use($designConst)

const $confConst = {
  install: Vue => Vue.prototype.$confConst = confConst
}
Vue.use($confConst)

Vue.use( CKEditor );

Vue.use(ViewUI, {
  transfer: true
});

Vue.use(viewer);

new Vue({
  router: router,
  store,
  render: h => h(App),
}).$mount('#app')
