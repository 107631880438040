import Vue from 'vue';
import router from '../router';
export default {
  /**
   * 以下页面注意处理：
   * 首次打开也会触发actived，全部keep-alive了，使用路由params.re刷新数据
   * 激活：先卸载，再挂载：on-refresh
   * 激活：判断route.params.re==1刷新数据
   *  */
  /**
   * orderItem注意处理：
   * 加载：第一次打开，可以为页面的tab-opts写入是否启用快速定位
   * 激活：启用：覆盖使用当前的快速定位
   * 激活：关闭：手动筛选
   */
  conf: {
    home: {
      label: "首页",
      name: "home"
    },
    order: {
      label: "委托单",
      name: "order"
    },
    orderAdd: {
      label: "委托单[新增]",
      name: "orderAdd"
    },
    orderEdit: {
      label: "委托单[详情]",
      name: "orderEdit"
    },
    orderItems: {
      label: "测试项目",
      name: "orderItems"
    },
    testingSyllabus: {
      label: "测试大纲",
      name: "testingSyllabus"
    },
    bom: {
      label: "物料清单",
      name: "bom"
    },
    bomSend: {
      label: "物料寄送",
      name: "bomSend"
    },
    startBill: {
      label: "测试流转单",
      name: "startBill"
    },
    cashCollectionPlan: {
      label: "回款计划",
      name: "cashCollectionPlan"
    },
    invoiceSend: {
      label: "发票寄送",
      name: "invoiceSend"
    },
    goods: {
      label: "样品&物料",
      name: "goods"
    },
    goodsEdit: {
      label_: "样品&物料",
      name: "goodsEdit"
    },
    stock: {
      label: "库存",
      name: "stock"
    },
    stockIn: {
      label: "入库",
      name: "stockIn"
    },
    stockOut: {
      label: "出库",
      name: "stockOut"
    },
    storage: {
      label: "仓库",
      name: "storage"
    },
    prototypesDeal: {
      label: "样品处理",
      name: "prototypesDeal"
    },
    equipment: {
      label: "仪器列表",
      name: "equipment"
    },
    equipmentMro: {
      label: "仪器维修单",
      name: "equipmentMro"
    },
    equipmentUseTrace: {
      label: "仪器使用记录",
      name: "equipmentUseTrace"
    },
    crmOrder: {
      label: "销售跟单",
      name: "crmOrder"
    },
    crmTrader: {
      label: "往来单位",
      name: "crmTrader"
    },
    dept: {
      label: "部门",
      name: "dept"
    },
    user: {
      label: "人员管理",
      name: "user"
    },
    guest: {
      label: "客户帐号",
      name: "guest"
    },
    role: {
      label: "角色列表",
      name: "role"
    },
    survey: {
      label: "问卷管理",
      name: "survey"
    },
  },
  isOpen(that, name) {
    let flag = false
    for (let x of that.$store.state.tabs) {
      if (x.name == name) {
        flag = true
        break
      }
    }
    return flag
  },
  /**
   * 1、从localstorage获取数据
   * 2、匹配localstorage.user和usersession
   * 3、获取处理后的name、tabs、locate、opts
   * 
   */
  /**
   * name：tab的key；route.name；重定向main获取usersession后返回的目标tab及对应路由。
   * tabs：初始化将打开的标签页，每个标签页对应路由。从localstorage获取后进行身份匹配，权限判断。
   * locate：展示在头部的委托单，用于搜索列表或侧窗详情快速填入委托单，公共变量。
   * opts：以tab.name作为键存储在localstorage的数组，值一般为存储id的对象。[{xxx:{id:number}}]。
   *  考虑也存储re:1，tab.open中把localstorage的re:1读取并写入route.params后删除localstorage的re，只使用一次，
   *  为解决刷新窗口后切换tab，目标不初始数据。
   */
  init(that) {
    console.log("tab init")
    // 初始化tabs、tab、order-locate、tab-opts
    // 此时user还是历史数据
    let user = that.$util.session("user")
    if (user) {
      user = JSON.parse(user)
    }
    // 缓存的tabs
    let tabs = that.$util.session("tabs")
    if (tabs) {
      tabs = JSON.parse(tabs)
    }
    // 缓存的“锁定委托单”
    let locate = that.$util.session("order-locate")
    if (locate) {
      locate = JSON.parse(locate)
    } else {
      locate = {} // 懒得后面判断空
    }
    // 缓存的tab页面的数据id
    let opts = that.$util.session("tab-opts")
    if (opts) {
      opts = JSON.parse(opts)
    } else {
      opts = {} // 懒得后面判断空
    }
    // 用于打开tab
    let name = "";
    // 缓存的用户是否匹配当前登录用户
    if (user && user.Id && user.Id == that.$store.state.user.Id) {
      console.log("“缓存用户数据”匹配“当前登录用户”")
      let tabs_ = [this.conf["home"]]
      // 缓存有tabs数据
      if (tabs && tabs.length > 0) {
        for (let x of tabs) {
          if (x && x.name != "home") {
            // TODO 逐个判断权限
            if (!this.conf[x.name].label) {
              if (opts[x.name].id) {
                x.label = this.conf[x.name].label_ + "[详情]"
              } else {
                x.label = this.conf[x.name].label_ + "[新增]"
              }
            }
            tabs_.push(x)
          }
        }
      }
      tabs = tabs_
      // 开始处理当前路由
      name = that.$route.name
      // route.name匹配tabs
      let nameInTabs = false
      for (let x of tabs) {
        if (x.name == name) {
          nameInTabs = true
          break
        }
      }
      if (nameInTabs == false) {
        name = "home"
      }
      // TODO 判断是否有当前路由权限
      // 不允许目标末端路由页面是mian，麻烦处理不选中tab情况
      if (name == "main") {
        name = "home"
      }
    } else { // 缓存用户数据”不匹配“当前登录用户
      console.log("“缓存用户数据”不匹配“当前登录用户”")
      name = "home"
      tabs = [this.conf["home"]]
      locate = {}
      opts = {}
    }
    that.$util.session("user", that.$store.state.user);
    that.$util.session("tabs", tabs);
    that.$util.session("order-locate", locate);
    // console.log("session tab-opts", JSON.stringify(opts))
    that.$util.session("tab-opts", opts);
    that.$store.commit("setTabs", tabs)
    that.$store.commit("setOrderLocate", locate)
    for (let i of tabs) {
      if (i.name != 'home' && i.name != that.$route.name) {
        opts[i.name].re = 1
      }
    }
    // console.log(opts)
    that.$store.commit("setTabOpts", opts)
    // 跳转
    // 不管什么页面，先导航去main停留，获取准备数据（usersession）
    that.$store.commit("setTabName", "")
    if (that.$route.name == "main") {
      // console.info("route.name == main")
      that.$store.commit("setTabName", "home")
      that.$router.replace({
        name: "home",
        params: { re: 1 }
      }).then(() => {
        that.userLoading = false
      })
    } else {
      /**
       * tabs的页面不使用mounted
       * 刷新窗口后，打开其他tab，将会同时触发mounted和activated:{re:1}，
       * 可能倒是重复调用接口
       * */
      /**
       * 注意这里不能直接使用opts[name]，opts赋值地址给了store.state
       * 使用opts会改变store.state的值，使下次tab.open会使用被修改了的值
       */
      let params = opts[name] ? that.$util.copy(opts[name]) : {}
      params.re = 1
      that.$router.replace({
        name: "main"
      }).then(res => {
        that.$store.commit("setTabName", name)
        that.$router.replace({
          name: name,
          params: params
        }).then(() => {
          that.userLoading = false
        })
      })
    }
  },
  /**
   * 
   * @param {*} that 获取vue组件实例
   * @param {*} name 路由的name、tab标签的name
   * @param {*} opt 将会缓存进浏览器(id)
   * @param {*} param 与opt构成route.params
   * @returns 
   */
  open: function (that, name, opt, param) {
    if (that.$route.name == name) {
      return
    }
    // TODO 判断权限
    let opts = that.$store.state.tabOpts
    if (opt) {
      if (!opts[name]) {
        opts[name] = {}
      }
      // 可能传0，将“编辑”tab转换为“新增”tab
      if (opt.id != undefined) {
        opts[name].id = opt.id
      }
    }
    // 缓存tab-opts，确保init时候在tab-opts村的re用掉。
    let opts_no_re = that.$util.copy(opts)
    delete opts_no_re[name]["re"]
    that.$util.session("tab-opts", opts_no_re)
    that.$store.commit("setTabOpts", opts_no_re)
    if (this.isOpen(that, name)) { // 已经打开
      // console.log("已打开的Tab", opt, opts[name])
      if (!this.conf[name].label) {
        let tabs = that.$store.state.tabs
        for (let i in tabs) {
          if (tabs[i].name == name) {
            if (opts && opts[name].id) {
              tabs[i].label = this.conf[name].label_ + "[详情]"
            } else {
              tabs[i].label = this.conf[name].label_ + "[新增]"
            }
            break
          }
        }
        that.$store.commit("setTabs", tabs)
      }
      that.$store.commit("setTabName", name)
      if (opt.go) {
        that.$router.replace({
          name: name,
          params: Object.assign({
            re: opt.re
          }, opts[name], param || {})
        })
      }
    } else {
      // console.log("未打开的Tab", opt, opts[name])
      let tabs = that.$store.state.tabs
      let tabObj = this.conf[name]
      if (!tabObj.label) {
        if (opts && opts[name].id) {
          tabObj.label = tabObj.label_ + "[详情]"
        } else {
          tabObj.label = tabObj.label_ + "[新增]"
        }
      }
      tabs.push(tabObj)
      that.$store.commit("setTabs", tabs)
      that.$store.commit("setTabName", name)
      that.$util.session("tabs", tabs)
      if (opt.go) {
        that.$router.replace({
          name: name,
          params: Object.assign({
            re: 1
          }, opts[name], param || {})
        })
      }
    }
  },
  close: function (that, name) {
    // this.$nextTick(() => {
    let tabs_ = that.$store.state.tabs
    let tabs = []
    let tabName = "" // 关闭后作为打开的
    let ready = false
    for (let x of tabs_) {
      if (x.name == name) {
        ready = true
      } else {
        tabs.push(x)
        if (ready) {
          tabName = x.name
          ready = false
        }
      }
    }
    if (!tabName) {
      tabName = tabs[tabs.length - 1].name
    }
    let opts = {}
    if (that.$store.state.tabOpts) {
      opts = that.$store.state.tabOpts
    }
    if (opts[name]) {
      opts[name] = {}
    }
    that.$util.session("tab-opts", opts)
    that.$store.commit("setTabOpts", opts)
    that.$util.session("tabs", tabs)
    that.$store.commit("setTabs", tabs)
    that.$store.commit("setTabName", tabName)
    if (tabName != that.$route.name) {
      that.$router.replace({
        name: tabName,
        params: opts[tabName]
      })
    }
    // })
  },
  closeLeft: function (that, name) {
    if (!name) {
      return
    }
    let flag = false;
    let tabs = [];
    for (let x of that.$store.state.tabs) {
      if (x.name == name) {
        flag = true;
        tabs.push(x);
      } else if (flag) {
        tabs.push(x);
      }
    }
    tabs.unshift(this.conf["home"]);
    that.$store.commit("setTabs", tabs)
    that.$util.session("tabs", tabs)
  },
  closeRight: function (that, name) {
    if (!name) {
      return
    }
    let flag = true;
    let tabs = [];
    for (let x of that.$store.state.tabs) {
      if (x.name == name) {
        flag = false;
        tabs.push(x);
      } else if (flag) {
        tabs.push(x);
      }
    }
    that.$store.commit("setTabs", tabs)
    that.$util.session("tabs", tabs)
  },
  closeOther: function (that, name) {
    if (!name) {
      return
    }
    let tabs = [];
    for (let x of that.$store.state.tabs) {
      if (x.name == name) {
        tabs.push(x);
      }
    }
    tabs.unshift(this.conf["home"]);
    that.$store.commit("setTabs", tabs)
    that.$util.session("tabs", tabs)
  },
  closeAll: function (that) {
    that.$store.commit("setTabs", [this.conf["home"]])
    that.$store.commit("setTabName", "home")
    that.$util.session("tabs", [this.conf["home"]])
    if (that.$route.name != "home") {
      that.$router.replace({
        name: "home"
      })
    }
  }
}