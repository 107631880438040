export default {
  // processing 需要系统用户关注，进行中
  // warning 流程走到客户方，停滞状态
  // error
  // success 
  // default
  // 
  // 流程
  /**
   * status: 
   * 0：未开始，1：进行中，2：已结束，98：暂停，99：作废
   */
  // 委托单
  order: {
    StatusBadge: { 0: "default", 1: "warning", 2: "processing", 3: "processing", 4: "success", 5: "success", 6: "default", 99: "error" },
    Status: { 0: "未生效", 1: "待客户签章", 2: "已生效", 3: "进行中", 4: "测试已完成", 5: "委托单已完成", 6: "已结束", 99: "作废" },
    StatusLst: [
      { key: 0, name: "未生效" },
      { key: 1, name: "待客户签章" },
      { key: 2, name: "已生效" },
      { key: 3, name: "进行中" },
      { key: 4, name: "测试已完成" },
      { key: 5, name: "委托单已完成" },
      { key: 6, name: "已结束" },
      { key: 99, name: "作废" }
    ],
    TestPurpose: { 1: "委托检测（提供判定结果）", 2: "委托测试（提供测试数据）", 99: "其他" },
    TestPurposeLst: [
      { key: 1, name: "委托检测（提供判定结果）" },
      { key: 2, name: "委托测试（提供测试数据）" },
      { key: 99, name: "其他" }
    ],
    ReportPostWay: { 1: "自取", 2: "邮寄" },
    ReportPostWayLst: [
      { key: 1, name: "自取" },
      { key: 2, name: "邮寄" }
    ],
    InvoiceType: { 1: "增值税普通发票", 2: "增值税专用发票" },
    InvoiceTypeLst: [
      { key: 1, name: "增值税普通发票" },
      { key: 2, name: "增值税专用发票" }
    ],
    PrototypeProcessWay: { 1: "自取", 2: "委托实验室托运（委托方付款）", 3: "委托实验室处理" },
    PrototypeProcessWayLst: [
      { key: 1, name: "自取" },
      { key: 2, name: "委托实验室托运（委托方付款）" },
      { key: 3, name: "委托实验室处理" }
    ]
  },
  // 委托单登录用户
  orderUser: {
    Role: { 1: "技术", 2: "商务", 3: "技术兼商务" },
    RoleLst: [{ key: 1, name: "技术" }, { key: 2, name: "商务" }, { key: 3, name: "技术兼商务" }],
    StatusBadge: { 0: "default", 1: "processing", 2: "default", 99: "error" },
    Status: { 0: "未生效", 1: "正常", 2: "不能查看数据", 99: "禁用" },
    StatusLst: [{ key: 0, name: "未生效" }, { key: 1, name: "正常" }, { key: 2, name: "不能查看数据" }, { key: 99, name: "禁用" }]
  },
  // 测试项目
  orderItems: {
    StatusBadge: { 0: "default", 1: "processing", 2: "warning", 3: "processing", 4: "warning", 5: "success", 6: "default", 98: "processing", 99: "default", 100: "error" },
    Status: { 0: "草稿", 1: "待确认测试大纲", 2: "待开启", 3: "进行中", 4: "暂停测试", 5: "测试完成", 6: "已结束", 98: "测试大纲已确认", 99: "作废", 100: "已取消" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "待确认测试大纲" },
      { key: 2, name: "待开启" },
      { key: 3, name: "进行中" },
      { key: 4, name: "暂停测试" },
      { key: 5, name: "测试完成" },
      { key: 6, name: "已结束" },
      { key: 98, name: "测试大纲已确认" },
      { key: 99, name: "作废" },
      { key: 100, name: "已取消" },
    ]
  },
  // 测试大纲
  testingSyllabus: {
    StatusBadge: { 0: "default", 1: "processing", 2: "processing", 3: "warning", 4: "success", 98: "error", 99: "error" },
    Status: { 0: "草稿", 1: "已发布", 2: "待客户确认", 3: "客户已确认", 4: "已生效", 98: "停用", 99: "作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "已发布" },
      { key: 2, name: "待客户确认" },
      { key: 3, name: "客户已确认" },
      { key: 4, name: "已生效" },
      { key: 98, name: "停用" },
      { key: 99, name: "作废" }
    ]
  },
  // 物料清单
  bom: {
    Type: { 1: "样品", 2: "仪器设备", 99: "其他" },
    TypeLst: [{ key: 1, name: "样品" }, { key: 2, name: "仪器设备" }, { key: 99, name: "其他" }],
    Party: { 1: "委托方", 2: "受委托方" },
    PartyLst: [{ key: 1, name: "委托方" }, { key: 2, name: "受委托方" }],
    StatusBadge: { 0: "default", 1: "processing", 2: "warning", 3: "processing" },
    Status: { 0: "未提交", 1: "正常", 2: "变更", 3: "已生效" },
    StatusLst: [{ key: 0, name: "未提交" }, { key: 1, name: "正常" }, { key: 2, name: "变更" }, { key: 3, name: "已生效" }]
  },
  bomSend: {
    StatusBadge: { 0: "default", 1: "warning", 2: "warning", 3: "processing", 4: "processing", 5: "success", 90: "processing" },
    Status: { 0: "草稿", 1: "待接收", 2: "接收待确认", 3: "接收完成", 4: "已部分入库", 5: "已全部入库", 90: "正在接收" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "待接收" },
      { key: 2, name: "接收待确认" },
      { key: 3, name: "接收完成" },
      { key: 4, name: "已部分入库" },
      { key: 5, name: "已全部入库" },
      { key: 90, name: "正在接收" }
    ]
  },
  bomReceive: {
    StatusBadge: { 0: "default", 1: "processing", 2: "warning", 3: "processing", 4: "success" },
    Status: { 0: "草稿", 1: "已提交", 2: "待入库", 3: "已部分入库", 4: "已全部入库" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "已提交" },
      { key: 2, name: "待入库" },
      { key: 3, name: "已部分入库" },
      { key: 4, name: "已全部入库" },
    ]
  },
  startBill: {
    StatusBadge: { 0: "default", 1: "warning", 2: "processing", 3: "success", 4: "error", 98: "default", 99: "default" },
    Status: { 0: "草稿", 1: "待审批", 2: "审批进行中", 3: "生效", 4: "未通过", 98: "已撤回", 99: "作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "待审批" },
      { key: 2, name: "审批进行中" },
      { key: 3, name: "生效" },
      { key: 4, name: "未通过" },
      { key: 98, name: "已撤回" },
      { key: 99, name: "作废" },
    ]
  },
  startBillSings: {
    Status: { 0: "未审核", 1: "通过", 2: "拒绝", 98: "打回" },
  },
  // 回款计划
  cashCollectionPlan: {
    StatusBadge: { 0: "default", 1: "processing", 2: "error" },
    Status: { 0: "草稿", 1: "执行中", 2: "已完成", 3: "逾期", 99: "作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "执行中" },
      { key: 2, name: "已完成" },
      { key: 3, name: "逾期" },
      { key: 99, name: "作废" },
    ]
  },
  // 发票（弃用）
  invoice: {
    Type: { 1: "增值税普通发票", 2: "增值税专用发票" },
    TypeLst: [{ key: 1, name: "增值税普通发票" }, { key: 2, name: "增值税专用发票" }],
    StatusBadge: { 0: "default", 1: "processing", 2: "error", 99: "default" },
    Status: { 0: "草稿", 1: "待签收", 2: "已签收", 99: "已作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "待签收" },
      { key: 2, name: "已签收" },
      { key: 99, name: "已作废" },
    ]
  },
  // 发票寄送
  invoiceSend: {
    StatusBadge: { 0: "default", 1: "processing", 2: "error", 99: "default" },
    Status: { 0: "草稿", 1: "待签收", 2: "已签收", 99: "已作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "待签收" },
      { key: 2, name: "已签收" },
      { key: 99, name: "已作废" },
    ]
  },
  // 物料
  goods: {
    Type: { 1: "客户物料", 2: "我方物料" },
    SubType: { 1: "样品", 99: "其他物料" }
  },
  // 入库
  stockIn: {
    StatusBadge: { 0: "default", 1: "warning", 2: "success", 3: "error", 99: "default" },
    Status: { 0: "草稿", 1: "待审核", 2: "审核通过", 3: "审核不通过", 99: "作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "待审核" },
      { key: 2, name: "审核通过" },
      { key: 3, name: "审核不通过" },
      { key: 99, name: "作废" },
    ],
    Type: { 1: "客户物料", 2: "我方物料" },
    SubType: { 1: "接收入库", 2: "检毕入库", 99: "其它入库" }
  },
  // 出库
  stockOut: {
    StatusBadge: { 0: "default", 1: "warning", 2: "success", 3: "error", 99: "default" },
    Status: { 0: "草稿", 1: "待审核", 2: "审核通过", 3: "审核不通过", 99: "作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "待审核" },
      { key: 2, name: "审核通过" },
      { key: 3, name: "审核不通过" },
      { key: 99, name: "作废" },
    ],
    Type: { 1: "客户物料", 2: "我方物料" },
    SubType: { 1: "检测出库", 2: "样品处理出库", 99: "其它出库" }
  },
  // 样品处理
  prototypesDeal: {
    Type: { 1: "由受委托方代为处理", 2: "由受委托方代为寄回", 3: "委托方自行取回" },
    TypeLst: [
      { key: 1, name: "由受委托方代为处理" },
      { key: 2, name: "由受委托方代为寄回" },
      { key: 3, name: "委托方自行取回" }
    ],
    StatusBadge: { 0: "default", 1: "processing", 2: "processing", 3: "default" },
    Status: { 0: "草稿", 1: "待确认", 2: "待签收", 3: "已结束" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "待确认" },
      { key: 2, name: "待签收" },
      { key: 3, name: "已结束" },
    ]
  },
  // 仪器
  equipment: {
    StatusBadge: { 0: "default", 1: "success", 2: "warning", 3: "error", 99: "default" },
    Status: { 0: "闲置", 1: "运行", 2: "维护", 3: "故障", 99: "报废" },
    StatusLst: [
      { key: 0, name: "闲置" },
      { key: 1, name: "运行" },
      { key: 2, name: "维护" },
      { key: 3, name: "故障" },
      { key: 99, name: "报废" }
    ]
  },
  // 仪器维修单
  equipmentMro: {
    StatusBadge: { 0: "default", 1: "processing", 2: "processing", 3: "default", 99: "error" },
    Status: { 0: "未生效", 1: "已生效", 2: "进行中", 3: "已结束", 99: "作废" },
    StatusLst: [
      { key: 0, name: "未生效" },
      { key: 1, name: "已生效" },
      { key: 2, name: "进行中" },
      { key: 3, name: "已结束" },
      { key: 99, name: "作废" }
    ]
  },
  // 仪器维修记录
  equipmentMroTrace: {
    StatusBadge: { 0: "default", 1: "processing", 2: "default" },
    Status: { 0: "未解决", 1: "已解决", 2: "不处理" },
    StatusLst: [
      { key: 0, name: "未解决" },
      { key: 1, name: "已解决" },
      { key: 2, name: "不处理" },
    ]
  },
  // 仪器使用记录
  equipmentUseTrace: {
    StatusBadge: { 0: "default", 1: "warning", 2: "success", 3: "error", 4: "processing", 98: "default", 99: "default" },
    Status: { 0: "草稿", 1: "未审核", 2: "同意使用", 3: "拒绝申请", 4: "使用中", 98: "使用结束", 99: "作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "未审核" },
      { key: 2, name: "同意使用" },
      { key: 3, name: "拒绝申请" },
      { key: 4, name: "使用中" },
      { key: 98, name: "使用结束" },
      { key: 99, name: "作废" },
    ]
  },
  crmOrder: {
    StatusBadge: { 0: "default", 1: "processing", 2: "success", 98: "error", 99: "default" },
    Status: { 0: "草稿", 1: "跟单中", 2: "已签单", 98: "已丢单", 99: "作废" },
    StatusLst: [
      { key: 0, name: "草稿" },
      { key: 1, name: "跟单中" },
      { key: 2, name: "已签单" },
      { key: 98, name: "已丢单" },
      { key: 99, name: "作废" }
    ]
  },
  // 往来单位
  crmTrader: {
    Type: { 1: "客户", 2: "供应商", 3: "分包机构", 4: "样品回收公司" },
    TypeLst: [
      { key: 1, name: "客户" },
      { key: 2, name: "供应商" },
      { key: 3, name: "分包机构" },
      { key: 4, name: "样品回收公司" }
    ],
    StatusBadge: { 0: "default", 1: "processing", 2: "error" },
    Status: { 0: "未生效", 1: "正常", 2: "无效" },
    StatusLst: [
      { key: 0, name: "未生效" },
      { key: 1, name: "正常" },
      { key: 2, name: "无效" }
    ]
  },
  // 系统用户
  user: {
    Type: { 1: "员工帐号", 2: "客户帐号" },
    TypeLst: [
      { key: 1, name: "员工帐号" },
      { key: 2, name: "客户帐号" }
    ],
    StatusBadge: { 0: "default", 1: "processing", 2: "default", 3: "error", 99: "error" },
    Status: { 0: "未生效", 1: "正常", 2: "未激活", 3: "审核未通过", 99: "禁用" },
    StatusLst: [
      { key: 0, name: "未生效" },
      { key: 1, name: "正常" },
      { key: 2, name: "未激活" },
      { key: 3, name: "审核未通过" },
      { key: 99, name: "禁用" }
    ]
  }
}