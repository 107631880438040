<template>
  <vue-viewer
    :full="imageList"
    :thumb="imageList"
    list-ul-class="total-viewer"
    multiple
    ref="viewer"
  ></vue-viewer>
</template>
<script>
export default {
  data() {
    return {
      imageList: [],
    };
  },
  mounted: function () {
    console.log(this.$refs.viewer);
    // 复写，获取src指向的图片的宽高尺寸
    this.$refs.viewer.getImgNaturalStyle = function (img, callback) {
      let maxW = document.body.clientWidth,
        maxH = document.body.clientHeight,
        z;
      var nWidth, nHeight;
      if (img.naturalWidth) {
        // 现代浏览器
        nWidth = img.naturalWidth;
        nHeight = img.naturalHeight;
        z = nWidth / nHeight;
        if (nWidth > maxW) {
          nHeight = maxW / z;
          nWidth = maxW;
        }
        if (nHeight > maxH) {
          nWidth = maxH * z;
          nHeight = maxH;
        }
      } else {
        // IE6/7/8
        var image = new Image();
        image.src = img.src;
        image.onload = function () {
          callback(image.width, image.height);
        };
      }
      return [nWidth, nHeight];
    };
    // ——————————
    this.$root.$on("view-imgs", (imgs, index) => {
      if (imgs.length > 0) {
        this.$refs.viewer.showFullViewer(index || 0);
      }
      this.imageList = imgs;
    });
  },
};
</script>
<style lang="less">
.full-image.animate-transform {
  background-color: #fff;
}
</style>
