<template>
  <div id="app">
    <router-view />
    <viewer />
  </div>
</template>

<script>
import viewer from "./components/Viewer";
export default {
  name: "App",
  components: { viewer },
  mounted: function () {
    console.log("App Mounted");
    this.$Message.config({
      duration: 5,
    });
  },
};
</script>

<style lang="less">
html,
body,
#app {
  height: 100%;
}
#app {
  font-family: Roboto, San Francisco, "Helvetica Neue", Helvetica, Arial,
    PingFangSC-Light, "Hiragina Sans GB", "WenQuanYi Micro Hei",
    "microsoft yahei ui", "microsoft yahei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}
</style>
