(function() {
  // 字符串|去除头尾空格
  if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function () {
      return this.replace(/(^\s*)|(\s*$)/g, '');
    }
  }
  // 字符在|转型日期
  String.prototype.toDate = function () {
    return new Date(this.replace(/-/g, "/").trim());
  }
  // 日期|格式化
  Date.prototype.format = function (fmt) { //author: meizz  
    var o = {
        "M+": this.getMonth() + 1, //月份  
        "d+": this.getDate(), //日  
        "h+": this.getHours(), //小时  
        "m+": this.getMinutes(), //分  
        "s+": this.getSeconds(), //秒  
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度  
        "S": this.getMilliseconds() //毫秒  
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  };
  // 日期|分割各单位值
  Date.prototype.toArray = function () {
    var myDate = this;
    var myArray = new Array();
    myArray[0] = myDate.getFullYear();
    myArray[1] = myDate.getMonth() + 1;
    myArray[1] = myArray[1] > 9 ? myArray[1] : '0' + myArray[1];
    myArray[2] = myDate.getDate();
    myArray[2] = myArray[2] > 9 ? myArray[2] : '0' + myArray[2];
    myArray[3] = myDate.getHours();
    myArray[3] = myArray[3] > 9 ? myArray[3] : '0' + myArray[3];
    myArray[4] = myDate.getMinutes();
    myArray[4] = myArray[4] > 9 ? myArray[4] : '0' + myArray[4];
    myArray[5] = myDate.getSeconds();
    myArray[5] = myArray[5] > 9 ? myArray[5] : '0' + myArray[5];
    myArray[6] = myDate.getDay();
    // [年,月,日,时,分,秒,周几]
    return myArray;
  }
  // 日期|加减日期
  Date.prototype.toNDate = function (unit, num) {
    var curday = this;
    if (unit === 'd') {
      return new Date(curday.getTime() + 86400000 * num);
    } else if (unit === 'w') {
      return new Date(curday.getTime() + 86400000 * 7 * num);
    } else if (unit === 'm') {
      let arr = curday.toArray();
      return new Date(arr[0] + '/' + (arr[1] + num) + '/' + arr[2] + ' ' + arr[3] + ':' + arr[4] + ':' + arr[5]);
    } else if (unit === 'y') {
      let arr = curday.toArray();
      return new Date((arr[0] + num) + '/' + arr[1] + '/' + arr[2] + ' ' + arr[3] + ':' + arr[4] + ':' + arr[5]);
    }
  }
})();